<section class="section has-background-white">
  <div class="container has-text-centered">
    <h1 class="title is-centered">Blog</h1>
    <h2 class="subtitle">
      Interesting Technical Information
    </h2>

      <div class="box has-text-left">
        <div class="content is-medium">
          <strong>Fade in Content using CSS</strong>

          <div class="tags are-medium">
            <span class="tag is-link">CSS</span>
          </div>

          <br>
          Here is an example of how to fade in content.

          In your global css define a css class to denote the animation.
          <pre><code class="css">.fadeIn "{{ '{' }}"
            -webkit-animation-name: fadeIn;
            animation-name: fadeIn;
            -webkit-animation-duration: 1s;
            animation-duration:  1s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
          "{{ '}' }}"</code></pre>

          Here the class is defined as fadeIn and the animation duration is set to 1s. Now define the animation.

          <pre><code class="css">@-webkit-keyframes fadeIn "{{ '{' }}"
            from"{{ '{' }}"
                  opacity: 0;
                "{{ '}' }}"
                to"{{ '{' }}"
                  opacity: 1;
                "{{ '}' }}"
              "{{ '}' }}"

              @keyframes fadeIn "{{ '{' }}"
                          from"{{ '{' }}"
                  opacity: 0;
                "{{ '}' }}"
                to "{{ '{' }}"
                  opacity: 1;
                "{{ '}' }}"
              "{{ '}' }}"</code></pre>

          This block tell the animation to fade from clear to full opacity over the course of 1 second.  This gives the
          loading content a softer feel on the transition when loading from page to page.

        </div>



      </div>

  </div>
</section>
