<section class="section has-background-white">
  <div class="container">
    <h1 class="title is-centered">Games Development</h1>
    <h2 class="subtitle">
      Technical Enjoyment
    </h2>
    <p>Utilizing the latest developments in the Unity game engine, we are looking to create enjoyable games that can grow our development skill while allowing us to pursue our interesting games designs.  Look for announcements of our first projects soon!</p>
  </div>
</section>

