import { BrowserModule } from '@angular/platform-browser';
import {isDevMode, NgModule} from '@angular/core';

import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { DevelopmentComponent } from './development/development.component';
import { GamesComponent } from './games/games.component';
import { ContactComponent } from './contact/contact.component';
import { AboutComponent } from './about/about.component';

import {IAppState, rootReducer, INITIAL_STATE} from './store';
import {DevToolsExtension, NgRedux, NgReduxModule} from '@angular-redux/store';
import {NgReduxRouter, NgReduxRouterModule} from '@angular-redux/router';
import {Router, RouterModule} from '@angular/router';
import { BlogComponent } from './blog/blog.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    DevelopmentComponent,
    GamesComponent,
    ContactComponent,
    AboutComponent,
    BlogComponent
  ],
  imports: [
    BrowserModule,
    NgReduxModule,
    NgReduxRouterModule.forRoot(),
    RouterModule.forRoot([])
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(ngRedux: NgRedux<IAppState>, devTools: DevToolsExtension, ngReduxRouter: NgReduxRouter){
    let enhancers = (isDevMode() && devTools.enhancer() != null) ? [devTools.enhancer()] : [];
    ngRedux.configureStore(rootReducer, INITIAL_STATE, [], enhancers);
  }


}



