<nav class="navbar" role="navigation" aria-label="main navigation">
  <div class="navbar-brand">
    <a class="navbar-item" href="https://scarboroughsoftware.com">
      SSV
    </a>
  </div>

  <div id="navbar" class="navbar-menu">
    <div class="navbar-start">
      <a (click)="changePanel(1, 'development')" class='navbar-item'>
        Software Development
      </a>

      <a (click)="changePanel(2, 'games')" class='navbar-item'>
        Games
      </a>

      <a (click)="changePanel(3, 'blog')" class="navbar-item">
        Blog
      </a>

      <a (click)="changePanel(4, 'about')"  class="navbar-item">
        About
      </a>

      <a (click)="changePanel(5, 'contact')" class="navbar-item">
        Contact
      </a>
    </div>
    <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <a class="button is-light">
            Log in
          </a>
        </div>
      </div>
    </div>
  </div>
</nav>
