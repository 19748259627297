
<app-navbar></app-navbar>
<html class="has-navbar-fixed-top has-background-grey-lighter" style="min-height: 500px">
<div class="columns ">
  <div class="column is-10 is-offset-1">
    <div class="tab-conent fadeIn animated" *ngIf="requestedTab == 1" >
      <app-development></app-development>
    </div>
    <div class="tab-conent fadeIn animated" *ngIf="requestedTab == 2">
      <app-games></app-games>
    </div>
    <div class="tab-conent fadeIn animated" *ngIf="requestedTab == 3">
    <app-blog></app-blog>
  </div>
    <div class="tab-conent fadeIn animated" *ngIf="requestedTab == 4">
      <app-about></app-about>
    </div>
    <div class="tab-conent fadeIn animated" *ngIf="requestedTab == 5">
      <app-contact></app-contact>
    </div>
  </div>
</div>
</html>
<app-footer></app-footer>
