<section class="section has-background-white">
  <div class="container">
    <h1 class="title is-centered">About Us</h1>
    <h2 class="subtitle">
      Welcome to Scarborough Software Ventures LLC.
    </h2>
    <p>We are a Small Business dedicated to pursuing innovative ideas in Software Development.  SSV is split into a Software Devleopment and a Gaming division.  With a background in engineering and a passion for software, SSV has completed projects for Fortune 100 companies and NASA's Human Space Flight Program.   We look forward to seeing what comes in the future.</p>
  </div>
</section>

