import {ChangeDetectorRef, Component, NgZone} from '@angular/core';
import {NgRedux, select} from '@angular-redux/store';
import {IAppState} from './store';
import {ActivatedRoute, Router} from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @select(state => state.status.currentTabIndex) currentIndex;
  title = 'SSV';

    tab  = 'development';
    requestedTab = 1;
    private ref: ChangeDetectorRef = null;

  constructor(private ngRedux: NgRedux<IAppState>, private router: Router, private route: ActivatedRoute, private refe: ChangeDetectorRef) {


    this.currentIndex.subscribe(value => {
      console.log(value);
      this.requestedTab = value;
    });

  }

  changeShowPanel(index, newPanel){

    this.ngRedux.dispatch({type: 'SET_TAB_INDEX', body: {
       currentTabIndex: index
    }
    });

    window.history.pushState('', '', window.location.origin + '/#/' + newPanel);

    this.tab = newPanel;
  }


}
