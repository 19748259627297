import {combineReducers} from 'redux';
import {routerReducer} from '@angular-redux/router';
import {tassign} from 'tassign';

export const SET_TAB_INDEX = 'SET_TAB_INDEX';

export interface IAppState{
  status: object;
}

const SHARED_INITIAL_STATE: object = {
  currentTabIndex : 1
};

export const INITIAL_STATE: IAppState = {

  status: SHARED_INITIAL_STATE

};

export const rootReducer = combineReducers({
  status: sharedReducer,
  router: routerReducer
});


function setCurrentTabIndex(state, action){
  return tassign(state, {currentTabIndex: action.body.currentTabIndex});
}



export function sharedReducer(state: object = SHARED_INITIAL_STATE, action): object{

  switch (action.type) {
    case SET_TAB_INDEX : console.log("setting tab"); return setCurrentTabIndex(state, action);
  }

  return state;
}
