import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  providers:[AppComponent ],
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  constructor(private appComponent: AppComponent) { }

  ngOnInit(): void {
  }

  changePanel(index, panel){
    console.log(panel);
    this.appComponent.changeShowPanel(index, panel);
  }


}
